.fontWeight-normal {
  font-weight: normal !important;
}

.fontWeightBold {
  font-weight: bold;
}

.fontSize2rem {
  font-size: 2rem !important;
}

.withoutBgButton {
  color: #f6a907;
  background: transparent 0% 0% no-repeat padding-box;
  padding: 0.5em 1.5em;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  text-transform: capitalize;
  text-align: center;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  height: auto;
}

.cardProfile {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 18px;
  line-height: 2.3;
  padding: 24px;
  margin-bottom: 6px;
}

.profileCardLeftSideTitle {
  color: #00000099;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  opacity: 0.7;
}

.profileCardRightSideName {
  color: #141414f4;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.colorCodeEF951C {
  color: #ef951c;
}

.site-collapse-custom-collapse {
  background-color: white !important;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #4b5660;
  opacity: 0.8;
}

.addNewRowButton {
  color: var(--primary-color) !important;
  border: none !important;
  font-family: "Poppins", sans-serif;
  opacity: 1;
  margin-right: 5px;
  font-size: 12px;
  width: 100px;
}

.deleteRowButton {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: red;
  color: white;
  padding: 7px;
}

.inputDivTag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  flex: 0.1;
}
.poppinsNormalFont {
  font-family: "Poppins", sans-serif;
  color: #58666e;
  font-weight: normal;
}
.twelveFontSize {
  font-size: 12px;
}
.fourteenFontSize {
  font-size: 14px;
}

.inputDivTag label {
  margin-right: 20px;
}
.inputDivTag label.spend-limits {
  width: 5.5rem;
}
.inputDivTag label.max-limits {
  width: 4rem;
}
.alignItemsToFlexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.inputTag,
.card-info .ant-select-selector {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
  color: #2e2e2e !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: bold;
  width: 130px !important;
  padding: 10px !important;
}
.card-info .ant-select {
  width: 130px !important;
}

.card-info .ant-select-selector {
  height: 3rem !important;
}

.card-info .ant-select-selection-item {
  bottom: 5px;
  padding-bottom: 45px;
}
.inputDivTag .ant-input-disabled,
.inputDivTag .ant-input-prefix {
  color: #2e2e2e !important;
  font-weight: bold;
}

.inputTag::placeholder {
  font-weight: normal;
}
.inputTag:focus {
  box-shadow: none !important;
}
.dropdowntag {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
}

.dropdowntag > span,
.ant-dropdown-menu-item > span {
  color: #2e2e2e !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
  padding: 3px;
  font-weight: 500;
}

.ant-dropdown-menu-item-active > span:hover {
  color: #3f81f4 !important;
}

.informationIcon {
  color: white;
  background-color: black;
  cursor: pointer;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 10px;
  font-size: 12px;
}
.minKycDiv {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 9px;
}
.F48C32ButtonColor {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.verifiedColor {
  color: #1cb110 !important;
}
.opacity05 {
  opacity: 0.5;
}
.card-info-flex-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.titleColorCode333333 {
  color: #333333;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.expensesAmount {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #4d4d4d;
}
.flex07 {
  flex: 0.7;
}
.flex03 {
  flex: 0.3;
}
.accordion-heading,
.accordion-heading .ant-collapse-header-text {
  font-size: 1.4rem;
  color: #333333 !important;
}

.ant-collapse-header-text {
  color: #333333 !important;
}
.kycImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  padding: 20px;
}
.kycImageName {
  color: #00000099;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  opacity: 0.6;
  margin-bottom: 10px;
}

.cardImage {
  width: 20rem;
}

.panelButton {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.68);
  font-weight: bold;
  padding-left: 20px;
}

.panelIcon {
  float: right;
  color: rgba(0, 0, 0, 0.68);
  padding-right: 15px;
}

.modalContent h3 {
  color: var(--bg-primary);
}

.modalContent span {
  color: rgba(0, 0, 0, 0.68);
}

.transactionMenu .anticon-caret-right {
  float: right;
}
.fb-btns button {
  background: #f7fffd 0% 0% no-repeat padding-box;
  border: 1px solid #6ae3c6;
  border-radius: 8px;
  color: #170a33;
}

.debit-card {
  padding: 1rem;
  background: #170a33 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin: 1.5rem;
  min-width: 22rem;
  min-height: 13rem;
  max-height: 13.3rem;
  max-width: 22rem;
  position: relative;
}

.z_card {
  position: absolute;
  right: -2px;
  top: -2px;
  background-image: url(../../assets/images/z_bg.png);
  background-size: contain;
  width: 43%;
  height: 72%;
  background-repeat: no-repeat;
  opacity: 0.2;
  border-top-right-radius: 10px;
}

.debit-card h2 {
  font-size: 1.1rem;
  color: #fff !important;
}

.card-usage {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
}

.card-usage .item {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
}
.card-usage .item div span {
  display: block;
  font-size: 0.9rem;
}
.card-usage .item div span:first-child {
  color: #333333;
}

.card-usage .item div span:last-child {
  color: #33333380;
  font-size: 0.8rem;
}

.right-alinged-modal.lg {
  width: 54rem !important;
}

.card-usage .switch {
  margin-left: 0.5rem;
}

.btn-menu-wrap {
  margin-right: 1rem;
}

.btn-menu-wrap .ant-btn {
  height: 2.8rem;
}

.card_ref {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_ref span {
  font-weight: 600;
  color: #333333;
}

.card_ref span:last-child {
  font-weight: 400;
}

.rbi {
  color: #454545;
  font-weight: 400 !important;
  margin-top: 0.5rem;
  display: inline-block;
}
.message {
  padding: 0 1rem;
}

.card-activation ul {
  list-style: none;
}

.card-activation ul span {
  display: block;
  color: #454545;
  font-size: 0.9rem;
}

.card-activation ul .title {
  color: #4a4a4a;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.card-activation .flex-start {
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 0rem;
}

.card-activation li {
  margin-bottom: 0.7rem;
}

.card-activation ul {
  background: #edf0f5;
  border-radius: 10px;
  padding: 2rem;
}

.card-form {
  display: flex;
  justify-content: space-between;
}

.form-header {
  color: #122549;
  opacity: 0.5;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.merchant-lock {
  display: flex;
  align-items: flex-start;
}

.merchant-lock p {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.merchant-lock p label {
  font-weight: 600;
  font-size: 1.2rem;
}

.merchant-lock p span {
  color: #212121;
  opacity: 0.6;
}

.merchant-lock input {
  display: inline-block;
  width: auto;
}

.hidden-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox-box {
  width: 18px;
  height: 18px;
  background-color: white;
  border: 0.75px solid #bfbfbf;
  border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  position: relative;
  top: 5px;
  cursor: pointer;
}

/* Checkmark when checked */
.custom-checkbox-box::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Checkbox checked styles */
.hidden-checkbox:checked + .custom-checkbox-box {
  background-color: #6ae3c6;
  border-color: #6ae3c6;
}

/* Show checkmark when checked */
.hidden-checkbox:checked + .custom-checkbox-box::after {
  display: block;
}

.card-form {
  padding: 1rem 4rem;
}

.card-show {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.debit-wrap {
  padding: 2rem;
  background-color: #f0fcf9;
  border-radius: 10px;
}

.status_activated {
  background: #2da70015 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border-color: #2da70015 !important;
}

.card-nm-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.75px solid #e5e5e5;
  border-radius: 6px;
  color: #f6a907;
  font-weight: 600;
  font-size: 1rem;
  height: 35px;
  padding: 0 1rem;
}

.card-secondary-button {
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  height: 43px;
  padding: 0 1.5rem;
  color: #4b5660;
  font-size: 1rem;
}

.card-activate-button {
  background: #48b553 0% 0% no-repeat padding-box;
  border: 1px solid #48b553;
  border-radius: 8px;
  height: 43px;
  padding: 0 1.5rem;
  color: #fff;
  font-size: 1rem;
}

.debit-card-details .tabs-sec {
  background: #f0fcf9;
}

.debit-card-details .tabs-sec li {
  color: #212121;
  opacity: 0.5;
}

.debit-card-details .tabs-sec li.active {
  color: #212121;
  opacity: 1;
}

.debit-card-details .tabs-sec ul li.active::before {
  background-color: #6ae3c6;
}

/* Wallet component css */
.wallet-container {
  padding: 20px;
  background-color: #fff;
  width: 400px;
  margin: 4rem;
}

.wallet-container .ant-divider-horizontal {
  margin: 10px 0 !important;
}

.wallet-container h3 {
  padding-bottom: 10px;
  color: #4b5660;
  border-bottom: 1px solid #eaeaea;
}

.wallet-section h4 {
  color: #122549;
  opacity: 0.5;
  margin: 10px 0;
  font-size: 1.2rem !important;
}
.wallet-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
}

.wallet-item:last-child {
  border-bottom: none;
}

.wallet-item-left {
  display: flex;
  align-items: center;
}
.wallet-item-left span {
  color: #333333;
}
.wallet-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.wallet-item-right {
  font-weight: bold;
  font-size: 1.2rem;
}

.card_switch_button {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
/* Card Balance Section */

.card-balance-container {
  width: 100%;
  padding: 20px;
}

.spend-limit,
.balance-section,
.last-charged {
  margin-bottom: 20px;
  max-width: 70%;
}

.spend-limit span,
.last-charged span {
  color: #000;
  opacity: 0.6;
  min-width: 20rem;
}
.spend-limit-wrap {
  display: flex;
  justify-content: space-between;
}

.spend-limit-wrap .spend-limit span {
  min-width: 12rem;
  display: inline-block;
}
.spend-limit .amount {
  font-weight: 600;
  font-size: 1.2rem;
}
.spend-limit .link {
  position: absolute;
  top: -1rem;
  right: 0px;
  color: #333333;
  display: inline-block;
  font-size: 0.95rem;
  cursor: pointer;
}
.spend-limit small {
  color: #a4a4a4;
  font-size: 0.9rem;
  font-weight: 500;
}
.spend-limit .link:hover {
  text-decoration: underline;
}
.balance-details span.label {
  font-size: 0.9rem;
  color: #a4a4a4 !important;
  opacity: 1;
}

.balance-details span {
  font-size: 0.95rem;
  color: #141414 !important;
  opacity: 1;
}

.progress-bar {
  background-color: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;
  height: 10px;
  width: 20rem;
}

.filled-bar {
  background-color: #2c034f;
  height: 100%;
}

.last-charged {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last-charged .last-charged-date {
  font-weight: bold;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.action-button {
  color: #6ae3c6;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}

.freeze-button {
  color: #00bfa5;
}
.action-button ~ .ant-divider-vertical {
  height: 2rem;
}
.block-button {
  color: #f44336;
}

.action-icon {
  margin-right: 5px;
  font-size: 1rem;
}

.freeze-popup .title,
.block-card-form .title {
  color: #212121;
  font-weight: 600;
  font-size: 1.1rem;
}

.freeze-popup .name {
  color: #212121;
  margin-bottom: 5px;
  font-weight: 500;
}

.block-card-form h3 {
  color: #212121;
  font-size: 1.2rem;
  font-weight: 600;
}

.reason-radios {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.reason-radios input {
  width: auto;
}

.reason-radios label {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.75px solid #d9d9d9;
  border-radius: 4px;
  color: #170a33;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.block-card-form .label,
.ship-addr label {
  color: #3b3b3b;
  margin-bottom: 5px;
  display: inline-block;
}
.block-card-form .label {
  margin-bottom: 10px;
}

.ship-addr .mail {
  background: #fafbfd 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 9px;
  padding: 1.5rem 1rem;
}

.ship-addr span.title {
  color: #3c4853;
  font-size: 1rem;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.ship-addr .link {
  display: block;
  margin-top: 18px;
  color: #6ae3c6;
  font-weight: 400 !important;
  cursor: pointer;
}
.events .ant-timeline-item-content {
  position: relative;
  top: -15px;
}
.events .ant-timeline-item-content label {
  color: #1f1f1f;
  font-weight: 500;
  margin-left: 1rem;
}

.events .ant-timeline-item-content span {
  color: #1f1f1f;
  opacity: 0.5;
  display: block;
  margin-left: 1rem;
}

.events .ant-timeline-item-head {
  width: 2.5rem;
  height: 2.5rem;
  background: #f0fcf9 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.events .ant-timeline li {
  min-height: 5rem;
}

.events .ant-timeline-item-tail {
  border-color: #6ae3c6 !important;
}

.card-limits table th {
  color: #767676;
  padding: 1rem;
  text-align: left !important;
  background: none;
}
.card-limits table td {
  padding: 1rem;
}

.card-limits {
  position: relative;
}
.card-limits .ant-table-content {
  border: 0.75px solid #dbdbdb;
  border-radius: 10px;
}
.card-limits tbody tr {
  border-bottom: 0.75px solid #dbdbdb;
}

.card-limits tbody tr td {
  border-right: 0.75px solid #dbdbdb;
}

.card-limits tbody tr td:last-child {
  border-right: none;
}

.advanced-controls label.title {
  margin-bottom: 1.5rem;
  font-size: 1.2rem !important;
}

.advanced-controls .ant-tag {
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
}

.advanced-controls .ant-divider {
  margin: 2.5rem 0 !important;
}
.advanced-controls {
  padding: 2rem;
  background: #fdffff 0% 0% no-repeat padding-box;
  border: 1px solid #dedede;
  border-radius: 8px;
}

.tm-line svg {
  color: #00bfa5;
}

/* Credit card css */

.credit-card {
  border-radius: 10px;
  padding: 20px;
  width: 40%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header h4 {
  color: #333;
}

.amount h1 {
  font-size: 36px;
  margin: 10px 0;
  color: #333;
}

.balance-bar {
  display: flex;
  height: 10px;
  background-color: #e0e0e0; /* Light grey */
  border-radius: 5px;
  overflow: hidden;
}

.balance-filled {
  background-color: #2d2d2d; /* Darker color for balance */
  width: 60%; /* Change based on actual balance ratio */
}

.balance-pending {
  background-color: #3ce0c3; /* Light green for pending */
  width: 20%; /* Change based on pending ratio */
}

.balance-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
}

.balance-info span {
  color: #21212180;
}

.col1,
.col2 {
  margin: 0 3px;
  opacity: 1 !important;
}
.col2 {
  color: #3ce0c3 !important;
}

.available strong {
  color: #212121 !important;
}

.amount-display h1 {
  font-size: 2rem;
  color: #212121;
  margin-bottom: 0.5rem;
}

.amount-display p {
  font-size: 1.1rem;
  color: #212121;
  margin: 0.5rem 0;
}

.dark .ant-popover-inner-content {
  background-color: #212121;
  border-radius: 10px;
}
.dark .ant-popover-arrow-content,
.ant-popover-arrow-content::before {
  background-color: #212121;
}
ul.credit {
  width: 15rem;
}

ul.credit li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

ul.credit label {
  min-width: 8rem;
}

.dark .ant-divider {
  border-color: #ffffff55;
  margin: 0.5rem 0 !important;
}
