.dashboard .ant-row {
  gap: 1.5rem;
  flex-wrap: nowrap;
  align-items: stretch;
}

.table-tabs {
  padding: 0 1rem !important;
  /* margin-bottom: 1rem !important; */
}

.dashboard-tabs.table-tabs {
  padding: 0 !important;
  margin-bottom: 1.2rem;
}
.dashboard-tabs li {
  font-weight: 600;
  opacity: 0.7;
}
.table-tabs .ant-tabs-tab-btn {
  padding: 1rem;
  cursor: pointer;
  color: #2f2f2f;
}

.table-tabs .tab-active {
  color: var(--primary-color);
  position: relative;
  opacity: 1;
}

.table-tabs .tab-active::before {
  height: 2.5px;
  background-color: var(--primary-color);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
}

.dashboard .ant-tabs-tab-btn {
  font-weight: 600;
}
.dashboard-title {
  margin-bottom: 10px;
}

.dashboard-title .card-title {
  color: rgba(18, 37, 73, 0.48);
  font-size: 1.2rem;
  font-weight: 600;
  padding-right: 10px;
}
.rtl .dashboard-title .card-title {
  padding-right: 0px;
}
.dashboard-content {
  min-height: 190px;
}
.dashboard-title .help-icon {
  color: #d3d9e1;
  cursor: pointer;
  font-size: 18px;
}
.rtl .dashboard-title .help-icon {
  margin-right: 10px;
}

.dashboard-content {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  box-shadow: 0px 3px 12px #e3e4e5;
  padding: 20px 28px;
  /* height: 100%; */
}

.account-card .dashboard-content {
  height: 100%;
}

.dashboard-table .dashboard-content {
  padding: 0;
  min-height: 25rem;
}
.card-label {
  color: rgba(5, 21, 44, 0.5);
  font-size: 1rem;
  font-style: "regular";
  display: block;
  margin-bottom: 5px;
}

.card-label-overdue {
  color: #ed0000;
  font-size: 1rem;
  font-style: "regular";
}

.card-label-value {
  color: #122549;
  font-size: 1.3rem;
  font-weight: 600;
  display: block;
}

.card-vertical-cont {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.card-footer-cont {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.circle-dashboard {
  background-color: rgba(63, 129, 244, 0.09);
  border-radius: 32px;
  height: 4rem;
  margin-right: 22px;
  width: 4rem;
}

.rtl .circle-dashboard {
  margin-left: 22px;
  margin-right: 0;
}

.circle-contact {
  align-items: center;
  background-color: #2f2f2f;
  border-radius: 32px;
  color: #ffffff;
  display: flex;
  font-size: 1.4rem;
  height: 50px !important;
  justify-content: center;
  width: 70px !important;
}

.contact-title {
  color: #00000099;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 26px;
}

.contact-text {
  color: #00000099;
  font-size: 1rem;
  line-height: 28px;
}
.download-app .dashboard-content {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.download-app .content {
  margin-left: 20px;
  margin-top: 60px;
}
.rtl .download-app .content {
  margin-right: 20px;
  margin-left: 0px;
}
.rtl .download-app .footer-contact .contact-text {
  margin-right: 20px;
  margin-left: 0px;
}
.download-app.footer-contact .contact-text {
  margin-left: 20px;
}
.rtl .download-app.footer-contact .contact-text {
  margin-right: 20px;
  margin-left: 0px;
}

.title-summary p {
  margin-bottom: 0;
}
.title-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.summary-wrap span.card-title {
  display: block;
  width: 100%;
}

.summary-wrap .ant-select-selector {
  background-color: transparent;
  border: none !important;
}

.highcharts-credits {
  display: none;
}
.quick-btns {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.4rem;
  margin-top: 0.3rem;
}
.quick-btns button,
.quick-btn {
  align-items: center;
  background: #e7f5f2 0 0 no-repeat padding-box;
  border: 0.75px solid #6ae3c6;
  border-radius: 6px;
  box-shadow: 0 3px 12px #a1bcb61a;
  color: #170a33;
  display: flex;
  font-weight: 500;
  gap: 8px;
  height: 40px !important;
  padding: 0.4rem 1.5rem;
}
