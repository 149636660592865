@import url(./components/Common/styles/searchinput.css);
@import url(./assets/css/profile.css);
@import url(../node_modules/swiper/swiper-bundle.css);
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("react-datepicker/dist/react-datepicker.css");

body {
  font-family: "Figtree", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blurred-row .blur {
  filter: blur(2px);
  cursor: not-allowed !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  --primary-color: #170A33;
  --bg-primary: #151424;
  --bg-secondary: #2d2c3a;
  --bg-light: #fafafa;
  --text-primary: #151424;
  --text-white: #fff;
  --text-secondary: rgba(51, 51, 51, 0.6);
  --text-c6: #c6c6c6;
  --text-d9: #d9d9d9;
  --text-be: #bebebe;
  --text-27: #272727;
  --text-21: #212121;
  --text-76: #767676;
  --text-7C: #7C7D7E;
  --text-4a: #4a4a4a;
  --text-e6: #e6e6e6;
  --text-e1: #e1f8d9;
  --text-2e: #2ea700;
  --text-db: #dbdbdb;
  --text-ed: #ed3b41;
  --text-f8: #f8f8f8;
  --text-f5: #f5f7fa;
  --text-52: #525353;
  --text-a4: #a4a4a4;
  --text-d6: #d6d6d6;
  --text-b4: #b4b4b4;
  --text-84: #846415 --text-user: #9e9e9e;
  --text-disable: #f7f6f7;
  --bg-orange-light: #2d2c3a;
}

.capitalize {
  text-transform: capitalize;
}

ul {
  list-style: none;
}

.fw-500 {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
span,
label,
button {
  color: var(--text-white);
  font-family: "Poppins", sans-serif;
}

.ant-badge-count {
  top: -10px !important;
  right: 0px !important;
}

/* button span {
  color: var(--text-27);
} */

.border-bottom-line {
  height: 0.1rem;
  background-color: #d9d9d9;
}

.color-846415 {
  color: #846415;
}

.ant-modal-close-x {
  color: #262626 !important;
}

.primaryButton {
  background: var(--primary-color) !important;
  border-radius: 0.3rem !important;
  border: var(--primary-color) !important;
  color: #fff;
  font-size: 1rem;
}

.errorMsg,
form .ant-form-item-explain {
  color: red !important;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.popup-imgs .ant-modal-close-x {
  color: #333;
}

.primaryButton span {
  color: var(--text-white) !important;
}

.merchant-btn {
  margin: auto;
  background-color: #fff;
}

.p0 {
  padding: 0 !important;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.merchant-btn span,
.ant-select-selection-search input {
  color: #272727;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}

button:disabled {
  opacity: 0.5;
}

a {
  text-decoration: none;
  color: var(--text-white);
}

.gap4 {
  gap: 4rem;
}

.success-toast {
  background: #36af47 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 100%;
  padding: 1rem 2rem;
  color: #fff;
  cursor: pointer;
}

.violations .item {
  display: block;
  margin-bottom: 10px;
}

.violations .item span {
  display: block;
  color: red;
}

.violations .item span.warn {
  color: orange;
}

.violations .base_currency {
  color: red;
}

.violations .item span.warn .base_currency {
  color: orange;
}

/* width css */

.w5 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25% !important;
}

.w30 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50% !important;
}

.w55 {
  width: 55%;
}

.w60 {
  width: 60%;
}

.w65 {
  width: 65%;
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w85 {
  width: 85%;
}

.w90 {
  width: 90%;
}

.w95 {
  width: 95%;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.h100vh {
  min-height: 100vh;
}

/* margin css */

.mlHalf {
  margin-left: 0.5rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml1 {
  margin-left: 1.5rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml3 {
  margin-left: 3rem;
}

.ml4 {
  margin-left: 4rem;
}

.ml5 {
  margin-left: 5rem;
}

.mb0 {
  margin-bottom: 0rem !important;
}

.mbHalf {
  margin-bottom: 0.5rem;
}

.mb0 {
  margin-bottom: 0rem;
}

.mb1 {
  margin-bottom: 1rem !important;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem !important;
}

.mb4 {
  margin-bottom: 4rem;
}

.mb5 {
  margin-bottom: 5rem;
}

.mt0 {
  margin-top: 0rem !important;
}

.mtHalf {
  margin-top: 0.5rem;
}

.mt1 {
  margin-top: 1rem !important;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem !important;
}

.mt4 {
  margin-top: 4rem;
}

.mt5 {
  margin-top: 5rem;
}

.mr1 {
  margin-right: 1rem !important;
}

.mr2 {
  margin-right: 2rem;
}

.mr3 {
  margin-right: 3rem;
}

.mr4 {
  margin-right: 4rem;
}

.mr5 {
  margin-right: 5rem;
}

.mAuto {
  margin: auto;
}

.m1 {
  margin: 1rem;
}

.m2 {
  margin: 2rem;
}

.m3 {
  margin: 3rem;
}

.m4 {
  margin: 4rem;
}

.m5 {
  margin: 5rem;
}

/* padding css */

.p0 {
  padding: 0rem !important;
}

.pHalf {
  padding: 0.5rem;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.p4 {
  padding: 4rem;
}

.p5 {
  padding: 5rem;
}

.pbHalf {
  padding-bottom: 0.5rem;
}

.pb0 {
  padding-bottom: 0rem !important;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pb5 {
  padding-bottom: 5rem;
}

.pt0 {
  padding-top: 0px !important;
}

.pt1 {
  padding-top: 1rem;
}

.pt1Half {
  padding-top: 0.5rem;
}

.ptHalf {
  padding-top: 1.3rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}

.pt5 {
  padding-top: 5rem;
}

.pr0 {
  padding-right: 0rem !important;
}

.pr1 {
  padding-right: 1rem;
}

.pr2 {
  padding-right: 2rem;
}

.pr3 {
  padding-right: 3rem;
}

.pr4 {
  padding-right: 4rem;
}

.pr5 {
  padding-right: 5rem;
}

.pl0 {
  padding-left: 0rem !important;
}

.pl1 {
  padding-left: 1rem;
}

.pl2 {
  padding-left: 2rem;
}

.pl3 {
  padding-left: 3rem;
}

.pl4 {
  padding-left: 4rem;
}

.pl5 {
  padding-left: 5rem;
}

/* display */

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid;
}

/* flex */

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-center,
.d-flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-center {
  display: flex !important;
  align-items: center !important;
}

.t-center {
  text-align: center;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}


.gap1 {
  gap: 1rem;
}

.gap2 {
  gap: 2rem;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff !important;
}

.ant-checkbox-disabled {
  opacity: 0.5;
}

/* font size  */
.fs-18 {
  font-size: 0.9rem;
}

.fs-20 {
  font-size: 1rem;
}


.fw-N {
  font-weight: normal;
}

.fw-M {
  font-weight: 500;
}

img {
  cursor: pointer;
}

.fw-600 {
  font-weight: 600 !important;
}

/* Normal CSS */

.primary-btn {
  background-color: var(--primary-color) !important;
  border-radius: 4px;
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
}

.nm-btn {
  background-color: white;
  border-radius: 4px;
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  color: #4b5660;
  border: 1px solid #d6d6d6;
}

.secondary-btn {
  background-color: #fff;
  border-radius: 4px;
  padding: 0.43rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.white-btn {
  color: var(--primary-color);
  background-color: white;
  border-radius: 4px;
  padding: 0.7rem 2rem;
  font-size: 1.1rem;
}

.filter-btn {
  border-radius: 4px;
  padding: 0.43rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  background: #fff6ef 0% 0% no-repeat padding-box;
  border: 1px solid #f48c33;
  color: #f48c33;
  opacity: 0.5;
}

.upload-btn {
  border-color: #CBCBCB !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  align-items: center;
  display: flex !important;
  min-width: 100px;
  justify-content: center;
  height: 42px !important;
}

.filter-btn.active {
  opacity: 1;
}

.btn-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 100px);
}

.cursor {
  cursor: pointer !important;
}

.field_note span {
  color: #767676;
  opacity: 1;
  font-size: 0.8rem;
  font-weight: 600;
}

/* Text CSS */
.filter-text {
  font-size: 1rem !important;
  font-weight: normal !important;
  color: #b4b4b4 !important;
}

.text-heading {
  text-align: left;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 2.4rem;
}

.text-heading.small {
  font-size: 1rem;
  font-weight: 600;
}

.text-sub-heading {
  color: var(--text-secondary);
  text-align: left;
  font-size: 1.1rem;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.detail-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--text-db);
  border-radius: 12px;
}

.ant-message .ant-message-custom-content .messageText {
  color: var(--text-primary);
}

.paycraft-table .ant-table-row {
  cursor: pointer;
}

.draggable .ant-table-body {
  overflow-y: auto !important;
}

.draggable .ant-table-cell-scrollbar {
  display: none !important;
}

h5.ant-typography,
.ant-typography h5 {
  color: #4b5660 !important;
}

.text-center {
  text-align: center;
}

.loadingDiv {
  height: 100vh;
  position: fixed;
  z-index: 1001;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingDiv img {
  margin-top: 300px;
}

.navButton {
  margin-left: 20px;
  height: 40px !important;
  border-radius: 6px !important;
}

.ant-upload-list-item-name {
  color: var(--text-primary);
}

.ant-divider-horizontal {
  border-top: 2px solid rgba(0, 0, 0, 0.06) !important;
  margin: 10px 0 24px 0 !important;
}

.note {
  font-size: 0.9rem;
  font-weight: 600;
  color: #262626;
  opacity: 0.7;
}

.message {
  font-weight: 500;
  color: #262626;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
  white-space: nowrap;
}

.customize {
  color: var(--primary-color) !important;
  font-weight: 600;
  cursor: pointer;
}

.add-btn {
  color: #ed3b41;
  font-weight: 600;
  cursor: pointer;
}

.ant-picker-prev-icon,
.ant-picker-super-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  color: #272727;
}

.company-select .ant-btn,
.company-select .ant-btn:hover,
.company-select .ant-btn:focus {
  background: transparent;
  border: none;
}

.company-select .ant-btn {
  width: 100%;
  height: 2.8rem;
}

.company-menu {
  width: 12rem;
  position: relative;
  left: -2px;
}

.company-select {
  border: 1px solid #eaeaea80;
  border-radius: 4px;
  width: 86%;
  margin-top: 1rem;
}

.paycraft-table .ant-checkbox-checked .ant-checkbox-inner::after {
  border-width: 4px !important;
  transform: rotate(45deg) scale(0.5) translate(-142%, -58%) !important;
  width: 10px;
  top: 28%;
  left: 16.5%;
}

.notifications {
  position: absolute;
  background: #fff;
  z-index: 1000;
  top: 3rem;
  left: -1rem;
  list-style: none;
  width: 22rem;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  max-height: 25rem;
  overflow-y: auto;
}

.notifications li label {
  font-weight: 600;
  display: block;
  color: #333333;
}

.base_currency {
  color: #333333;
  display: inline !important;
  margin-right: 2px;
  padding: 0 !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: progress !important;
}

.notifications li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 10px;
}

.notifications li:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.reports-div .ant-checkbox-disabled {
  display: none;
}

.status {
  color: var(--text-2e);
  /* background: var(--text-e1); */
  border-radius: 7px;
  padding: 0.3rem 1.5rem;
  display: inline-block;
  min-width: 90px;
  text-align: center;
  text-transform: capitalize;
}

.dim-text {
  opacity: 0.5;
  color: #00000099;
  font-weight: 500;
}

.status_pending_approval,
.status_unsubmitted,
.status_pending_recovery {
  color: #f6a907;
  /* background: #fef4de; */
}

/*.status_reported {
  color: #f6a907;
  background: #fef4de;
} */

.unseen {
  background: #fef4de;
}

.status_pending_reimbursement,
.status_recovered {
  color: #45a0fe;
  /* background: #e1f0ff; */
}

.status_approved {
  color: #35aa09;
  /* background: #e1f8d9; */
}

.status_deactivated,
.status_rejected {
  color: #f15363;
  /* background: #fce3e5; */
}

.status_text_pending_approval {
  color: #f6a907;
}

.status_text_pending_reimbursement {
  color: #45a0fe;
}

.status_text_approved {
  color: #35aa09;
}

.status_text_rejected {
  color: #f15363;
}

.required {
  position: relative;
}

.required::after {
  content: "*";
  position: absolute;
  top: 0;
  right: -10px;
  color: red;
}

.required1::after {
  content: "*";
  position: absolute;
  top: 0;
  right: -10px;
  color: red;
}

.exchange-rate {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  height: 32px;
  position: absolute;
  right: 5rem;
  bottom: 0rem;
}

.exchange-rate h5 {
  margin: 0;
  /* Remove top margin for the heading */
  font-weight: 400;
  font-size: 12px;
  /* Adjust the heading font size */
}

.exchange-rate p {
  font-size: 14px;
  /* Adjust the paragraph font size */
  color: #333;
  /* Example text color */
  font-weight: 600;
  margin-bottom: 0;
}

.rotating {
  transition: 0.3s ease;
  animation: rot 3s linear infinite;
}

.expensePanel .ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

@keyframes rot {
  100% {
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  html {
    font-size: 10px;
  }

  /* .paycraft-table {
    max-width: 800px;
  } */
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
  html {
    font-size: 14px !important;
  }

  .login-form h3 {
    font-size: 2rem !important;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 20px;
  }

  body {
    width: 1580px;
    margin: auto;
  }
}

.Toastify__toast-container {
  padding: 50px 20px !important;
  width: 600px !important;
  z-index: 10000000 !important;
}

.Toastify__toast--success {
  background: #ddffe9 !important;
  border: 1px solid #dbdbdb;
  border-radius: 9px;
}

.Toastify__toast--warning {
  background: #f7f3c9 !important;
  border: 1px solid #dbdbdb;
  border-radius: 9px;
}

.toastify-body {
  display: flex;
  align-items: center;
}

.toastify-body span {
  color: #000000 !important;
  font-size: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.toastify-content .toastify-title {
  color: #3c4853 !important;
  font-size: 20px;
  font-weight: 700;
}

.toastify-content .toastify-message {
  color: #3c4853 !important;
  font-size: 20px;
}

.header-bill-panel {
  align-items: center;
  border: 1px solid #edf0f5;
  display: flex;
  justify-content: space-between;
  /* padding-right: 20px; */
  padding: 2rem;
}

.header-bill-data {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
}

.header-bill-data .title {
  color: #4b5660 !important;
  /* font-size: 30px; */
  font-size: 1.7rem;
  line-height: 37px;
  font-weight: bold;
}

.header-bill-data .subtitle {
  color: #2b2b2b !important;
  /* font-size: 16px; */
  font-size: 0.9rem;
  font-weight: 600;
}

.header-bill-data .subtitle.subtitletwo {
  font-weight: normal;
}

.header-bill-status {
  border-radius: 7px;
  padding: 7px 17px;
  margin-top: 45px;
}

.header-bill-status.status-pending {
  background-color: #fef4de !important;
  color: #f6a907 !important;
  font-size: 20px;
}

#create-file-tab .ant-tabs-nav {
  margin-bottom: 0px !important;
}

#create-file-tab .ant-tabs-tab {
  padding: 12px 30px !important;
}

#create-file-tab .ant-tabs-tab div {
  font-size: 18px;
  font-variant: "medium";
}

.file-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.file-desc {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px;
  line-height: 18px;
  margin-left: 10px;
}

.file-delete-btn {
  color: #3f81f4 !important;
  font-size: 18px;
}


.ant-select-selection-item {
  font-family: "Figtree", sans-serif;
  font-size: 15px !important;
}

/* navigation ui css start */

.notifications_ui {
  margin-top: 2rem;
}

.notifications_ui .Desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: #fafcff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  opacity: 1;
}

.notifications_ui .Desktop .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications_ui .Desktop .icon span {
  color: #363636;
  font-size: 1.18rem;
  font-weight: normal;
  margin-left: 1rem;
}

.notifications_ui .Desktop .icon img {
  width: 1.42rem;
  height: 1.23rem;
}

.notifications_ui .Desktop Button {
  padding: 0rem;
  cursor: pointer;
}

.notifications_ui .Desktop Button span {
  color: #3f81f4;
  font-size: 1.1rem;
  font-weight: normal;
  text-transform: capitalize;
}

.notifications_ui .Desktop Button:hover {
  background: #fafcff;
}

.notifications_ui .container {
  display: flex;
  align-items: center;
  padding: 1.2rem 1rem;
}

.notifications_ui .not-seen {
  background: #e0ecff;
}

.notifications_ui .loop_div {
  cursor: pointer;
}

.notifications_ui .loop_div:hover {
  cursor: pointer;
  background: #a9c9ffab;
  border-radius: 5px;
}

.notifications_ui .container .left_part {
  margin-right: 1.87rem;
}

.container .left_part .left_part_icon {
  width: 2.5rem;
  height: 2.5rem;
  background: #eff5ff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .left_part .left_part_icon img {
  width: 1.15rem;
  height: 1.27rem;
}

.notifications_ui .container .right_part h4 {
  color: #363636;
  opacity: 1;
  font-size: 1.18rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.notifications_ui .container .right_part span {
  color: #767676;
  font-size: 1rem;
  font-weight: normal;
}

.notifications_ui .bottom_line {
  height: 0.5px;
  background: #d9d9d9;
  border-radius: 6px;
  opacity: 1;
}

.ant-modal-title {
  font-size: 1.3rem !important;
}

.select-period-btn {
  background: #fafafa !important;
  border: 1px solid #d6d6d6 !important;
  border-radius: 6px !important;
  height: 40px !important;
}

.select-period-btn span {
  color: #4b5660 !important;
}

/* advance filter css start  */
.advance-filter-btn {
  border-top: 1px solid #b2b2b2 !important;
}

.advance-filter-btn .ant-dropdown-menu-title-content {
  color: #3f81f4 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.advance-filter-btn .ant-dropdown-menu-title-content img {
  width: 1rem !important;
  margin-right: 0.2rem !important;
}

.advance-filter-btn {
  margin: -4px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.advance-filter-values {
  font-size: 0.9rem;
  color: #4b5660;
  font-weight: normal;
  background: #e3e9f3;
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 0.5rem;
}

.advance-filter-values span svg {
  width: 0.65rem;
  margin-left: 0.3rem;
  color: #363636;
  cursor: pointer;
}

.role-section-label {
  display: block;
  color: #333;
  margin: 0.5rem 0;
  position: relative;
  top: -5px;
  left: -5px;
  font-weight: bold;
  font-size: 1.2rem !important;
  text-transform: capitalize;
}

.enable-portal span {
  font-size: 1rem;
  font-weight: 500;
  color: #000 !important;
}

.enable-portal-label label {
  font-size: 1rem;
  font-weight: 500;
  color: #000 !important;
  margin-left: 0.5rem;
  opacity: 0.6;
}

.customized-wrap .ant-select {
  min-width: 140px;
  max-width: 140px;
}

.customized-wrap .ant-input {
  height: 42px;
}

.qc-status-text_pass {
  color: #35aa09;
}

.qc-status-text_failed {
  color: #f15363;
}

.qc-status-text_onhold {
  color: #f6a907;
}

.returns-checkbox .ant-checkbox+span {
  color: black;
  font-size: 1rem;
  opacity: 0.8;
}



.table.tab {
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
  font-weight: 600;
  height: 2.5rem;
  padding: 0 1rem;
  color: #11111180;
}

/* Active tab style */
.table.tab.active {
  color: var(--primary-color);
  background-color: #6ae3c7;
  margin: 5px;
  border-radius: 5px;
}

.table.tabs {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  font-size: 0.9rem;
}
.v-line{
  position: absolute;
  right: -40%;
  top: 5%;
  content: "";
  height:90%;
  width: 2px;
  background-color: #EAEAEA;
}


/* #onfido-mount{
  width: 100%;
  height: 100%;
  min-height: 600px;
} */

@import url(./components/Payments/SendMoney/send_money.css);
@import url(./components/Payments/RequestMoney/request_money.css);
@import url(./components/Dashboard2/dashboard.css);
@import url(./components/Common/styles/bank.css);
@import url(./components/Payments/PaymentOptions/pay_options.css);
