.send-payment label,
.send-payment .ant-radio-group span,
.ant-input-prefix {
  color: #2e2e2e;
}
.send-payment .ant-input-prefix {
  margin-right: 10px;
}
.ends {
  display: flex;
  flex-direction: column;
}

.send-payment .sec-head {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
}

.send-payment .tabsSection span.active::after {
  background-color: #6ae3c6;
  bottom: -1.3rem;
}
.send-payment
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.send-payment
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #6ae3c6;
  background-color: #6ae3c6 !important;
}
.send-payment .ant-steps-item-icon {
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}
.send-payment .ant-steps-item-title {
  font-size: 1rem;
}
.send-payment .ant-steps-item-title::after {
  width: 100px;
}
.send-payment .ant-steps {
  width: 60%;
  margin: auto;
}

.step_parent.send-payment .ant-steps {
  width: 80%;
  margin: auto;
}

.step-2.ant-steps-item {
  flex: 0.7 0.5;
}

.send-payment .ant-steps-item-wait .ant-steps-item-icon {
  background: #f0f5f4 0% 0% no-repeat padding-box;
}

.send-payment .ant-steps-item-wait .ant-steps-item-icon span {
  color: #333333;
  opacity: 0.6;
}

.send-payment .bank-details {
  width: 100%;
  background: #fafbfd 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  padding: 20px;
  border-radius: 9px;
  margin-top: 1rem;
}

.send-payment .nickname,
.send-payment .legal-name {
  margin-bottom: 15px;
}

.send-payment .label {
  display: block;
  color: #8b8b8b;
  font-size: 0.9rem;
  margin-bottom: 3px;
}

.bal-details .value,
.send-payment .value {
  font-size: 1rem;
  color: #3c4853;
  font-weight: 600;
  margin-bottom: 6px;
}

.send-payment .bank-info {
  background: #fafbfd 0% 0% no-repeat padding-box;
  border: 0.75px solid #e9eaeb;
  border-radius: 9px;
  padding: 10px;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.send-payment .acc-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.send-payment .bank-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.send-payment .bank-icon {
  width: 2.8rem;
  height: 2.8rem;
}

.send-payment .account-details,
.send-payment .bank-location {
  color: #7d7d7d;
  font-size: 14px;
}

.send-payment .payment-details {
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  margin-top: 4rem;
  border: 0.75px solid #e9eaeb;
  background-color: #fff;
}

.section-header {
  margin-bottom: 20px;
}

.section-header h2,
.payment-container h2 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #333;
}

.section-header p {
  font-size: 1rem;
  color: #848485;
  margin-bottom: 0;
}

.amount {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.details {
  margin-bottom: 20px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.memo {
  background-color: #fafbfd;
  padding: 1rem;
  border-radius: 8px;
  border: 0.75px solid #e9eaeb;
  margin-bottom: 20px;
}

.memo-label {
  display: block;
  color: #7d7d7d;
  font-size: 14px;
}

.memo-value {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.back-btn,
.send-btn {
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.back-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6ae3c6;
  color: #6ae3c6 !important;
  height: 41px;
  font-size: 1rem;
}

.send-btn {
  background: #6ae3c6 0% 0% no-repeat padding-box;
  border: 1px solid #6ae3c6;
  color: #fff;
  font-weight: 500;
  height: 41px;
  font-size: 1rem;
  color: var(--primary-color);
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.send-payment .payment-details .value {
  text-align: right;
}

.email-receipt {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
}

.email-receipt label {
  color: #3b3b3b;
  font-size: 0.9rem;
}
.email-receipt input {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #e9eaeb;
}
.send-payment .payment-container {
  margin-top: 3rem;
  margin-left: 2rem;
}

.send-payment .payment-container .payment-details {
  margin-top: 2rem;
}

.send-payment .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.font-big {
  font-size: 2.2rem !important;
}

.send-payment .item {
  margin-bottom: 1rem;
  position: relative;
}

.send-payment .item .value {
  padding: 0;
}

.send-payment .ant-btn > span {
  color: #6ae3c6;
}

.send-payment .ant-btn {
  display: flex;
  align-items: center;
  gap: 7px;
}

.send-payment .ant-col .ant-card {
  max-height: 21rem;
  min-height: 21rem;
}

.item .right {
  position: absolute;
  right: 0;
  top: 4px;
}

.account-details .bal-sec {
  margin: 0;
}

.account-details .sec-btn {
  height: 39px;
  padding: 0;
  margin: 0;
  padding: 0 1rem;
  background-color: #fff;
  border-radius: 4px;
}

.sec-btn svg {
  fill: #6ae3c6;
  transform: scale(1.4);
}

.send-payment .action-btns {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
}

.send-payment .ant-select-selection-item {
  font-weight: 500;
}

.payment-details-container .details-card {
  background: #fafbfd 0% 0% no-repeat padding-box;
  border: 0.75px solid #e9eaeb;
  border-radius: 9px;
  padding: 1rem;
  margin-top: 3rem;
}

.payment-details-container .amount {
  margin-top: 1rem !important;
}

.imgs-wrap {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.imgs-wrap .img {
  width: 10rem;
  height: 11rem;
  position: relative;
  background: #e3e3e3 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgs-wrap img {
  width: 80%;
  height: 100%;
}

.file-close {
  position: absolute;
  top: -5px;
  right: -15px;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.file-eye {
  position: absolute;
  width: 2rem !important;
  height: 1.5rem !important;
  display: none;
  cursor: pointer;
}
.img:hover .file-eye {
  display: inline;
}
.img:hover::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #00000030;
}
