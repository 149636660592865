.alerts {
  margin-top: 2rem;
}
.alerts .sec-head h2 {
  color: #170a33;
}
.alerts .sub-sec-head h3 {
  color: #170a33;
}
.alerts .sec-head {
  padding: 2rem;
}
.alerts .sec-head p {
  color: #2b2b2b;
  margin-bottom: 0;
}

.alerts .close {
  top: 3rem;
  right: 2rem;
}

.alerts .tab-menu {
  background: #f0fcf9 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  gap: 3rem;
}

.alerts .tab-menu li {
  color: #212121;
  opacity: 0.6;
  font-weight: 500;
  cursor: pointer;
}

.alerts .tab-menu li {
  color: #212121;
  opacity: 0.6;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.alerts .tab-menu li.active {
  opacity: 1;
}
.alerts .tab-menu li.active::after {
  width: 100%;
  content: "";
  height: 3px;
  background-color: #6ae3c6;
  bottom: -1rem;
  position: absolute;
  left: 0;
}

.alert-row {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;
}

.alert-row label {
  color: #212121;
  font-weight: 500;
}
.alert-row span.sub {
  color: #212121;
  opacity: 0.57;
  font-size: 0.9rem;
  display: block;
  margin-bottom: 10px;
  max-width: 60rem;
}

.alert-input {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  max-width: 200px;
  min-width: 200px;
}

.alert-row.flex-between button.btn {
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 0.7rem 1rem;
  height: 40px;
  color: #170a33;
  font-size: 0.9rem;
  min-width: 12rem;
}

.alerts table thead .ant-table-cell {
  background: #f0f0f0 0% 0% no-repeat padding-box;
}

.alerts
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.alerts .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 6px;
}
.alerts .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

.alerts .ant-table-container table > tbody > tr td:first-child {
  border-left: 1px solid #f0f0f0;
}
.alerts .ant-table-container table > tbody > tr td:last-child {
  border-right: 1px solid #f0f0f0;
}

.security .alert-row {
  padding: 0 5px;
}
