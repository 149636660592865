.add-currency-modal .ant-modal-body {
    min-height: calc(100vh - 10rem) !important;
  }
  .add-currency-modal .ant-modal-body form {
    min-height: calc(100vh - 10rem) !important;
  }
  .add-currency-modal .ant-modal-body form .form-wrapper {
    min-height: calc(100vh - 10rem) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .form-select .pp-dropdown .ant-select-selector {
    height: 30px !important;
  }
  .form-select .pp-dropdown .ant-select-selection-search-input {
    height: 30px !important;
  }
  .form-select .pp-dropdown .ant-select-selection-item {
    height: 30px !important;
  }
  .form-select .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
  
  /* top header button */
  .btn-section {
    display: flex !important;
    justify-content: flex-end !important;
  }
  .exchange-rates-btn span {
    color: #727272;
  }
  .exchange-rates-btn {
    border-radius: 5px !important;
    background: #fafafa !important;
    border: 1px solid #d9d9d9 !important;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    height: 41px;
  }
  .exchange-rates-btn .switch-btn {
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
  }
  .exchange-rates-btn .ant-switch-checked {
    background-color: #6ae3c6 !important;
  }
  .exchange-rates-btn:hover span {
    color: #6ae3c6 !important;
  }
  .exchange-rates-btn:hover {
    border: 1px solid #6ae3c6 !important;
  }
  .modal-text {
    font-size: 1.2rem !important;
    font-weight: 500;
  }
  .add-currency-modal
    .form-wrapper
    .form-fields
    .addonn-css
    .ant-input-group-addon {
    border: none !important;
    background: #ffffff !important;
  }
  .add-currency-modal
    .form-wrapper
    .form-fields
    .addonn-css
    .ant-input-group
    .ant-input {
    text-align: right !important;
  }
  .add-currency-modal
    .form-wrapper
    .form-fields
    .addonn-css
    .ant-input-group
    .ant-input {
    text-align: right !important;
  }
  .add-currency-modal .form-wrapper .form-fields .addonn-css .pp-form-input {
    border-bottom: none !important;
  }
  .add-currency-modal
    .form-wrapper
    .form-fields
    .addonn-css
    .pp-form-input
    .ant-input {
    border-bottom: 1px solid #d9d9d9 !important;
  }
  
  .base_badge .ant-ribbon.ant-ribbon-placement-start {
    left: -25px !important;
  }
  .base_badge_sales .ant-ribbon.ant-ribbon-placement-start {
    left: -18px !important;
  }
  
  .base_badge .ant-ribbon,
  .base_badge_sales .ant-ribbon {
    top: -20px !important;
    padding: 0 6px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .add-currency-modal.data-model .ant-modal-body {
    padding: 0;
  }
  
  /* tallyCOlor : #1D191A */
  .exchange-note {
    background: #e0f7ff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #454545;
    padding: 0.7rem 1rem;
    margin-bottom: 1rem;
  }
  