.business-registration-container
  .business_upload
  .upload-documents-section
  span:first-child {
  width: 100%;
}

.business-registration-container .business_upload .upload_file .upload_buttom {
  width: 11.12rem;
}

.business-registration-container
  .business_upload
  .upload-documents-section-label {
  font-size: 14px;
  color: rgb(59, 59, 59);
}

.send-payment .upload-sec {
  border: 0.75px solid #e9eaeb;
}

.upload-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 1.7rem;
  justify-content: flex-start;
  margin-top: 2rem;
}

.upload-sec .image_name {
  display: flex;
  align-items: flex-start;
}
.upload-sec .image_section {
  align-items: center;
  background: #fafafa;
  border-radius: 4px;
  display: flex;
  height: 8rem !important;
  justify-content: center;
  max-width: 13rem !important;
}
.upload-sec .image_section img {
  max-width: 13rem !important;
}

.upload_file .upload_buttom {
  width: 18.12rem;
  height: 3.1rem;
  margin-top: 2rem;
  border-radius: 5px;
  background-color: #f4f2f9;
  cursor: pointer;
  font-weight: 600;
  border-color: #d9d9d980;
  display: flex;
  justify-content: center;
}

.upload-sec .title,
.upload-sec .supported,
.upload_file .upload_buttom span {
  color: #293241 !important;
}

.upload-sec .title {
  font-size: 1.2rem;
  font-weight: 600;
}
