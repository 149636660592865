.payment-layout {
  background: #212121 !important;
  box-shadow: 0px 3px 12px #3b3b3b0a;
  color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 90%;
  display: flex;
  margin: auto;
  max-height: 100vh;
  gap: 3rem;
  position: relative;
  top: 3rem;
}

.payment-layout .ant-divider-vertical {
  border-left: #ffffff38 1px solid;
  height: 100%;
}
.bank-details-card .ant-divider-horizontal {
  border-top: #ffffff38 1px solid !important;
}

.bank-details-card .ant-typography {
  color: #fff;
}

.payment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.payment-layout .download:hover,
.payment-layout .ant-btn-primary:hover,
.payment-layout .ant-btn-primary:focus,
.payment-layout .download {
  background: #6ae3c610;
  border: none;
  border-radius: 6px;
  height: 45px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.ant-typography {
  font-family: "Figtree", sans-serif;
}

.detail-box h4.ant-typography {
  margin-top: 0.5rem !important;
  margin-bottom: 1.5rem;
}

.payment-card .detail-box .ant-typography {
  color: #fff;
}
.payment-card,
.attachments-card,
.bank-details-card {
  color: white;
  margin-bottom: 20px;
}

.active.download span {
  color: #6ae3c6 !important;
}

.payment-card {
  margin-top: 2rem;
}

.amount-box {
  background-color: white;
  color: black;
  border-radius: 8px !important;
  padding: 20px;
  margin-top: 20px;
}
.amount-box .ant-divider {
  margin-bottom: 0.7rem !important;
}

.attachments-card {
  margin-top: 3rem;
}

.attachments-card h4 {
  color: #fff !important;
  opacity: 0.8;
  font-weight: 500 !important;
}
.attachment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #6a6a6a;
}

.attachment-wrap .attachment-item:last-child {
  border-bottom: none;
}

.tabs .ant-btn {
  border-radius: 5px;
}

.attachment-wrap,
.payment-form {
  background-color: #354642 !important;
  border: 1px solid #6a6a6a !important;
  border-radius: 4px;
}

.attachment-item .ant-typography {
  display: flex;
  align-items: center;
  color: #fff;
  gap: 10px;
}
.ben-details .attachment-item .ant-typography span {
  display: flex;
  align-items: center;
  max-width: 20rem;
  min-width: 20rem;
  justify-content: space-between;
  gap: 10px;
}
.sub-text {
  margin: 1.5rem 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.bank-details-card .ant-typography-secondary {
  color: #ffffff !important;
  opacity: 0.8;
}

.bank-details-card h5.ant-typography {
  color: #ffffff !important;
  margin-bottom: 1rem;
}
.ach.attachment-wrap {
  padding: 0.5rem 1rem;
  min-width: 20rem;
  max-width: 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.ben-details .attachment-item {
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
}

.ben-details strong {
  font-weight: 500 !important;
  opacity: 0.6;
  min-width: 17rem;
}

.ben-details .attachment-item .ant-typography {
  align-items: flex-start;
}

.dim {
  opacity: 0.5;
  font-size: 0.9rem;
}

.attachment-item .dim {
  font-weight: 400 !important;
  margin-top: 4px;
}

/* Payment Form CSS */

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border: none;
}

.payment-layout input {
  background-color: #354642;
  border: 0.75px solid #6a6a6a;
  border-radius: 4px;
  color: white;
  height: 48px;
}

.payment-form .ant-select {
  width: 100%;
}

.payment-form .ant-select-selector,
.payment-form .ant-select-selector input {
  background-color: #354642 !important;
  border: 0.75px solid #6a6a6a !important;
  border-radius: 4px;
  color: white;
  height: 48px !important;
  width: 100%;
}

.payment-form .ant-select-selection-placeholder,
.payment-form .ant-select-selection-item {
  height: 48px !important;
  display: flex;
  align-items: center;
}
select {
  background-color: #243b5e;
  color: white;
}

.error {
  color: #ff3860;
  font-size: 12px;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #3b7dd8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.payment-form {
  padding: 2rem;
  margin-top: 3rem;
}

.card-info .inputs input {
  border: none;
}

.card-info .inputs {
  border: 0.75px solid #6a6a6a;
}

.inputs .bb {
  border-bottom: 0.75px solid #6a6a6a !important;
}

.inputs .br {
  border-right: 0.75px solid #6a6a6a !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--bg-option) !important;
}
