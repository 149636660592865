.organisation {
    padding: 1rem;
  }
  
  .organisation .organisation_section {
    width: 100%;
    padding-top: 3rem;
    min-height: calc(100vh - 7.7rem);
  }
  
  .organisation .organisation_container {
    width: 80%;
    margin: 0 auto;
  }
  
  .organisation .admin_name {
    color: #262626;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;
  }
  
  .organisation .description {
    color: #767676;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .organisation .organisation_cards_section {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 2rem;
    border: 1px solid #d8d8d8;
    margin-top: 2rem;
  }
  
  .organisation .organisation_card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #d8d8d8;
    border-radius: 12px;
    padding: 20px 20px 30px 20px;
    position: relative;
  }
  
  .organisation .organisation_logo {
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    border-radius: 10px;
  }
  
  .organisation .organisation_logo img {
    width: 65%;
    height: 75%;
    border-radius: 5px;
  }
  
  .organisation .organisation_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .organisation .organisation_name {
    color: #262626;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .organisation .user_name {
    color: #262626;
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .organisation .organisation_create,
  .organisation .organisation_id,
  .organisation .organisation_admin {
    color: #767676;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: center;
  }
  
  .organisation .role {
    color: #363636;
    font-weight: 600;
  }
  
  .organisation .button_setion {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  
  .organisation .go_to_organisation {
    background: #ffffff;
    border: 1px solid #3f81f4;
    border-radius: 6px;
    color: #3f81f4;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 6px 10px;
  }
  
  .organisation .go_to_organisation.primarycolor {
    background: #3f81f4;
    border: 1px solid #3f81f4;
    border-radius: 6px;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 6px 10px;
    min-width: 13rem;
  }
  
  .organisation .delete_organisation {
    background: #ffedee;
    border-radius: 6px;
    padding: 6px 10px;
    margin-top: 0.7rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #ed3b41;
  }
  
  .organisation .delete_organisation.no_drop {
    cursor: no-drop;
  }
  
  .organisation .badge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .organisation .badge img {
    width: 100%;
  }
  
  .portal-checkbox .ant-checkbox-wrapper {
    margin-right: 0.5rem;
  }
  
  .portal-checkbox {
    margin-bottom: 1rem;
  }
  
  .portal-field-label .main-text {
    margin-bottom: 0rem;
    font-size: 1.1rem;
  }
  
  .portal-field-label .sub-text {
    color: #000;
    opacity: 0.8;
    font-size: 0.9rem;
  }
  
  .view-details .company-profile h3 {
    color: #170a33;
  }
  
  .upload-profile-pic {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    border: 0.5px solid #e6e6e6;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upload-profile-pic.present img{
    height: 7rem;
    border-radius: 50%;
  }
  .upload-profile-pic img {
    height: 2.5rem;
  }
  
  .company-profile .logo-sec {
    display: flex;
    align-items: start;
  }
  
  .company-profile .logo-sec .details {
    margin-left: 2rem;
    margin-top: 0.5rem;
  }
  .company-profile .logo-sec .details strong {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
  }
  .company-profile .logo-sec .details p {
    color: #3c4853;
    margin-top: 5px;
    font-size: 1.2rem;
  }
  
  .company-profile .flex-between button {
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    height: 40px;
    color: #4b5660;
    padding: 0 2rem;
  }
  
  .company-profile .ant-divider-horizontal {
    margin-top: 2rem !important;
  }
  
  .company-details,
  .company-address {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .company-details .item {
    min-width: 14rem;
    max-width: 14rem;
  }
  .company-address .item {
    min-width: 24rem;
    max-width: 24rem;
  }
  
  .primary-contact .item {
    min-width: 24rem;
    max-width: 24rem;
  }
  .company-details .item label {
    color: #00000099;
    opacity: 0.5;
    font-weight: 500;
  }
  
  .company-details .item span {
    color: #141414f4;
    font-weight: 500;
    font-size: 1.1rem;
  }
  .company-logo-modal .ant-upload-drag-container {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 230px;
  }
  .company-logo-modal .ant-upload-drag-container span.cloud {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    max-width: 70%;
  }
  .company-logo-modal .ant-upload-drag-container p {
    color: #b3b3b3;
    font-size: 0.9rem;
  }
  
  .company-logo-modal strong {
    color: #141414f4;
    font-size: 1rem;
  }
  