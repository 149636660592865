.ant-col-12.name-details,
.ant-col-12.full_name-details {
  display: flex;
  align-items: flex-start;
}

.name-details img {
  margin-right: 1.5rem;
  width: 8rem;
}

.name-details p,
.full_name-details p {
  color: #3c485380;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.name-details p b,
.full_name-details p b {
  color: #3c4853;
  font-size: 1.5rem;
  font-weight: 700;
}

.name-details .profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-col-12.d-flex {
  display: flex;
}

.amt {
  color: #3c4853;
  font-weight: 700;
  font-size: 1.7rem;
}

.name-right p {
  margin-bottom: 0;
}

.name-right p span {
  color: #3c4853;
  font-size: 0.8rem;
}

.other-info h4 {
  color: #4b5660;
  font-weight: 600;
  font-size: 1.1rem;
}

.contact .dim {
  color: #00000099;
  opacity: 0.5;
  font-size: 0.95rem;
  font-weight: 600;
}

.other-info label,
.other-info p label {
  color: #000000;
  opacity: 0.4;
  font-size: 0.95rem;
  font-weight: 500;
}

.other-info p {
  margin-bottom: 3px;
  color: #141414;
  font-size: 1.1rem;
  font-weight: 600;
}

.contact {
  padding: 1rem;
  border: 1px solid #c4c4c480;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
}

.contact .user-img {
  width: 3rem;
  margin-right: 1.2rem;
}

.contact .dim {
  font-size: 1rem;
}

.progress {
  width: 100%;
  height: 17px;
  background: #e3e9f3 0% 0% no-repeat padding-box;
  border-radius: 9px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.progress .active-progress {
  position: absolute;
  left: 0;
  top: 0;
  background: #1c83fc 0% 0% no-repeat padding-box;
  border-radius: 9px;
  height: 17px;
  transition: width 1s ease-out;
}

.other-info .header {
  font-size: 1.37rem;
  font-weight: bold;
  color: #4b5660;
}

.other-info .card_switch_button label {
  color: #212121;
  opacity: 1;
  font-weight: 600;
  font-size: 1.12rem;
}

.other-info .card_switch_button small {
  color: #333333;
  opacity: 0.57;
  font-size: 0.87rem;
  font-weight: 600;
}

.setting_header .other-info .ant-switch-checked {
  background-color: #6ae3c6 !important;
}

.collapse_button_none .ant-collapse-header {
  display: none;
}

.collapse_button_none .ant-collapse-item {
  border-bottom: none !important;
}

.collapse_button_none .inputDivTag label {
  color: #58666e;
  opacity: 1;
  font-size: 1.1rem;
  font-weight: normal;
}

.collapse_button_none .inputTag {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;
}

.collapse_button_none .inputDivTag label.spend-limits {
  width: 7.5rem !important;
}

.collapse_button_none .ant-select-selector {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  color: #2e2e2e !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: bold !important;
  width: 130px !important;
  padding: 0px 10px !important;
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.button_container .approve {
  height: 42px;
  border-radius: 10px;
  font-weight: 500;
}

.collapse_button_none .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.customer_portal_invite {
  color: #3f81f4;
  cursor: pointer;
}

.customer_portal_invite_button:disabled {
  cursor: not-allowed;
}
